import Cher from '@wearemojo/cher';

export enum HandleableErrorCode {
	access_denied = 'access_denied',
	already_registered = 'already_registered',
	deletion_request_already_exists = 'deletion_request_already_exists',
	discount_already_applied = 'discount_already_applied',
	efficacy_onboarding_already_submitted = 'efficacy_onboarding_already_submitted',
	email_requires_verification = 'email_requires_verification',
	identifier_already_attached = 'identifier_already_attached',
	magic_link_already_approved = 'magic_link_already_approved',
	magic_link_token_invalidated = 'magic_link_token_invalidated',
	nonce_expired = 'nonce_expired',
	not_active_member = 'not_active_member',
	user_not_consented = 'user_not_consented',
	user_not_found = 'user_not_found',
	user_not_subscribed = 'user_not_subscribed',
	user_signup_already_attributed = 'user_signup_already_attributed',
	verified_registration_required = 'verified_registration_required',
}

export type HandleableErrorMeta = {
	[HandleableErrorCode.access_denied]: {};
	[HandleableErrorCode.already_registered]: {};
	[HandleableErrorCode.deletion_request_already_exists]: {};
	[HandleableErrorCode.discount_already_applied]: {};
	[HandleableErrorCode.efficacy_onboarding_already_submitted]: {};
	[HandleableErrorCode.email_requires_verification]: {};
	[HandleableErrorCode.identifier_already_attached]: {
		identifierId: string;
	};
	[HandleableErrorCode.magic_link_already_approved]: {
		approvalCode: string;
	};
	[HandleableErrorCode.magic_link_token_invalidated]: {
		reason: {
			code: 'authz_issued' | 'magic_link_rejected';
		};
	};
	[HandleableErrorCode.nonce_expired]: {};
	[HandleableErrorCode.not_active_member]: {};
	[HandleableErrorCode.user_not_consented]: {
		status: 'awaiting_response' | 'consent_withdrawn';
	};
	[HandleableErrorCode.user_not_found]: {};
	[HandleableErrorCode.user_not_subscribed]: {};
	[HandleableErrorCode.user_signup_already_attributed]: {};
	[HandleableErrorCode.verified_registration_required]: {};
};

export type HandleableError<TCode extends HandleableErrorCode> = Cher<
	TCode,
	HandleableErrorMeta[TCode]
>;

import { HelpButton } from '@wearemojo/ui-components';

import useAppIntercom from '../hooks/useAppIntercom';
import { PresentIntercomParams } from '../utils/analytics/intercom.types';

type Props = {
	context: string;
	hideHelpLabel?: boolean;
	type: 'support';
};

const IntercomButton = ({ context, hideHelpLabel, type }: Props) => {
	const { unreadMessagesCount, presentIntercom } = useAppIntercom();

	const onPress = () => {
		const payload: PresentIntercomParams = { context, type: 'support' };

		presentIntercom(payload);
	};

	if (type !== 'support') {
		throw new Error(`Unsupported help type: ${type}`);
	}

	return (
		<HelpButton
			onPress={onPress}
			hasActivity={!!unreadMessagesCount}
			hideLabel={hideHelpLabel}
		/>
	);
};

export default IntercomButton;

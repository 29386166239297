import { ModalHeader, ModalHeaderProps } from '@wearemojo/ui-components';

import IntercomButton from './IntercomButton';

type Props = ModalHeaderProps & {
	helpType?: 'support';
	hideHelpLabel?: boolean;
	helpContext: string;
};

const ModalScreenHeader = ({
	helpType = 'support',
	helpContext,
	hideHelpLabel,
	...rest
}: Props) => {
	return (
		<ModalHeader
			{...rest}
			supportButton={
				<IntercomButton
					type={helpType}
					context={helpContext}
					hideHelpLabel={hideHelpLabel}
				/>
			}
		/>
	);
};

export default ModalScreenHeader;

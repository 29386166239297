import { UIContext } from '@wearemojo/ui-components';
import { useCallback, useContext, useMemo, useRef } from 'react';

import { useAppSelector } from '../hooks/useAppSelector';
import useBackendConfig from '../hooks/useBackendConfig';
import useNetworkState from '../hooks/useNetworkState';
import { useClientContext } from '../providers/ClientContextProvider';
import { useSubscriptionContext } from '../providers/SubscriptionStateProvider';
import { useTrackEventLogRocket } from '../services/logrocket';
import useIsStaffMember from '../store/api/hooks/useIsStaffMember';
import { selectOriginalUserId, selectUserId } from '../store/session';
import { useTrackEventAppsFlyer } from '../utils/analytics/appsflyer';
import { useTrackEventCustomerio } from '../utils/analytics/customerio';
import { useTrackEventFirebase } from '../utils/analytics/firebase';
import { useTrackEventIntercom } from '../utils/analytics/intercom';
import { useTrackEventMixpanel } from '../utils/analytics/mixpanel';
import { useTrackEventMojo } from '../utils/analytics/mojo';
import { logger } from '../utils/logging';
import { navigationRef } from '../utils/navigation';
import { AnalyticsEvent } from './AnalyticsEvent';
import { AnalyticsProviderName, TrackEventHook } from './types';
import { parsedBrowserName } from './useragent';

const providerAllowedEvents: Partial<
	Record<AnalyticsProviderName, AnalyticsEvent[]>
> = {
	[AnalyticsProviderName.AppsFlyer]: [
		AnalyticsEvent.auth_requested,
		AnalyticsEvent.iap_checkout_button_pressed,
		AnalyticsEvent.iap_subscription_completed,
		AnalyticsEvent.iap_subscription_failed,
	],
	[AnalyticsProviderName.Firebase]: [
		AnalyticsEvent.screen_viewed,
		AnalyticsEvent.iap_checkout_button_pressed,
		AnalyticsEvent.iap_subscription_completed,
		AnalyticsEvent.iap_subscription_failed,
	],
	[AnalyticsProviderName.Customerio]: [
		AnalyticsEvent.activity_completed,
		AnalyticsEvent.activity_learning_completed,
		AnalyticsEvent.checkout_close_button_pressed,
		AnalyticsEvent.efficacy_measurement_completed,
		AnalyticsEvent.efficacy_measurement_dismissed,
		AnalyticsEvent.iap_checkout_button_pressed,
		AnalyticsEvent.iap_subscription_completed,
		AnalyticsEvent.iap_subscription_failed,
		AnalyticsEvent.mp_experiment_started,
		AnalyticsEvent.notification_permission_granted,
		AnalyticsEvent.notification_permission_requested,
		AnalyticsEvent.notification_time_preference,
		AnalyticsEvent.payment_failed_cta_pressed,
		AnalyticsEvent.payment_paywalled_cta_pressed,
		AnalyticsEvent.subscription_canceled,
		AnalyticsEvent.tracking_button_pressed,
	],
	[AnalyticsProviderName.Intercom]: [
		AnalyticsEvent.activity_completed,
		AnalyticsEvent.activity_learning_completed,
		AnalyticsEvent.auth_completed,
		AnalyticsEvent.auth_cred_received,
		AnalyticsEvent.auth_requested,
		AnalyticsEvent.checkout_close_button_pressed,
		AnalyticsEvent.efficacy_measurement_completed,
		AnalyticsEvent.efficacy_measurement_dismissed,
		AnalyticsEvent.error_presented,
		AnalyticsEvent.iap_checkout_button_pressed,
		AnalyticsEvent.iap_subscription_completed,
		AnalyticsEvent.iap_subscription_failed,
		AnalyticsEvent.loading_indicator_persisted,
		AnalyticsEvent.mp_experiment_started,
		AnalyticsEvent.notification_permission_granted,
		AnalyticsEvent.notification_permission_requested,
		AnalyticsEvent.notification_time_preference,
		AnalyticsEvent.payment_failed_cta_pressed,
		AnalyticsEvent.payment_paywalled_cta_pressed,
		AnalyticsEvent.screen_viewed,
		AnalyticsEvent.subscription_canceled,
		AnalyticsEvent.tracking_button_pressed,
		AnalyticsEvent.web_continue_link_pressed,
	],
};

const providersNames = [
	AnalyticsProviderName.AppsFlyer,
	AnalyticsProviderName.Firebase,
	AnalyticsProviderName.Customerio,
	AnalyticsProviderName.Intercom,
	AnalyticsProviderName.LogRocket,
	AnalyticsProviderName.Mixpanel,
	AnalyticsProviderName.Mojo,
];

type Primitive = string | boolean | number;

export const useTrackEvent: TrackEventHook = () => {
	// @TODO: Rewrite to use single instance (e.g. context or ref)
	const appsFlyer = useTrackEventAppsFlyer();
	const firebase = useTrackEventFirebase();
	const customerio = useTrackEventCustomerio();
	const intercom = useTrackEventIntercom();
	const logRocket = useTrackEventLogRocket();
	const mixpanel = useTrackEventMixpanel();
	const mojo = useTrackEventMojo();
	const backendConfig = useBackendConfig();
	const context = useClientContext();
	const { isStaffMember } = useIsStaffMember().data ?? {};
	const userId = useAppSelector(selectUserId);
	const originalUserId = useAppSelector(selectOriginalUserId);
	const subscriptionState = useSubscriptionContext();
	const networkType = useNetworkState().networkType;

	const uiContext = useContext(UIContext);

	const enrichmentFields =
		useRef<Record<string, object | Primitive | Primitive[] | undefined>>();

	enrichmentFields.current = {
		mojo_system: backendConfig.system,
		mojo_env: backendConfig.env,
		mojo_tag: backendConfig.tag,
		mojo_user_authenticated: userId != null,
		mojo_user_impersonated: !!originalUserId && originalUserId !== userId,
		mojo_is_staff_member: isStaffMember,
		mojo_is_active_member: subscriptionState?.isActiveMember,
		mojo_subscription_status:
			subscriptionState === null ? 'none' : subscriptionState?.status,
		mojo_subscription_provider: subscriptionState?.provider ?? 'none',
		mojo_subscription_store: subscriptionState?.store ?? undefined,
		mojo_client_platform: context?.client.platform,
		mojo_client_version: context?.client.version ?? undefined,
		mojo_client_build: context?.client.build ?? undefined,
		mojo_client_git_commit: context?.client.gitCommit ?? undefined,
		mojo_locale_languages: context?.locale.languages ?? undefined,
		mojo_locale_timezone: context?.locale.timezone ?? undefined,
		mojo_ui_form_factor: uiContext.formFactor,
		mojo_ui_theme: uiContext.theme,
		mojo_ui_reduce_transparency: uiContext.reduceTransparency,
		mojo_current_route: navigationRef.isReady()
			? navigationRef.getCurrentRoute()
			: undefined,
		mojo_parsed_browser_name: parsedBrowserName,
		mojo_network_type: networkType,
	};

	const analyticsTriggers = useMemo(
		() => ({
			[AnalyticsProviderName.AppsFlyer]: appsFlyer,
			[AnalyticsProviderName.Firebase]: firebase,
			[AnalyticsProviderName.Customerio]: customerio,
			[AnalyticsProviderName.Intercom]: intercom,
			[AnalyticsProviderName.LogRocket]: logRocket,
			[AnalyticsProviderName.Mixpanel]: mixpanel,
			[AnalyticsProviderName.Mojo]: mojo,
		}),
		[appsFlyer, customerio, firebase, intercom, logRocket, mixpanel, mojo],
	);

	return useCallback(
		(eventName, payload, transformer) => {
			const enrichedPayload = { ...payload, ...enrichmentFields.current };
			const resolvedTransformer: NonNullable<typeof transformer> =
				transformer ?? ((_, p) => p);
			for (const providerName of providersNames) {
				const allowedEvents = providerAllowedEvents[providerName];
				const trigger = analyticsTriggers[providerName];

				if (allowedEvents && !allowedEvents.includes(eventName)) continue;

				try {
					const providerPayload = resolvedTransformer(
						providerName,
						enrichedPayload,
					);

					if (providerPayload) {
						trigger(eventName, providerPayload);
					}
				} catch (e) {
					logger.captureError('[analytics.trackEvent] provider failed', {
						error: e,
					});
				}
			}
		},
		[analyticsTriggers],
	);
};

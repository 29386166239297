import { FragmentMagicLinkRequesterType } from '@wearemojo/sanity-schema';
import {
	HelpButton,
	MagicLinkRequesterView,
	ModalHeader,
} from '@wearemojo/ui-components';

export default ({
	onPressDismiss,
	onPressSupport,
	magicLinkRequesterFragment,
	onExchangeMagicLinkCode,
	codeLength,
}: {
	onPressDismiss?: () => void;
	onPressSupport?: () => void;
	magicLinkRequesterFragment: FragmentMagicLinkRequesterType;
	onExchangeMagicLinkCode: (code: string) => Promise<void>;
	codeLength: number;
}) => (
	<MagicLinkRequesterView
		navHeader={
			<ModalHeader
				noBorder
				onPressClose={onPressDismiss}
				supportButton={
					onPressSupport && <HelpButton onPress={onPressSupport} hideLabel />
				}
			/>
		}
		magicLinkRequesterFragment={magicLinkRequesterFragment}
		onExchangeMagicLinkCode={onExchangeMagicLinkCode}
		codeLength={codeLength}
	/>
);
